import React from 'react';
import Lottie from 'lottie-react-web';

import anim from 'assets/lottie/hero-test.json';
import { LayoutBase as Layout } from "layouts";
import { SEO } from 'components';


class LottiePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimPlay: true,
      animSpeed: 1
    };
  }

  render () {
    const { location, pageContext } = this.props
    const { animSpeed, isAnimPlay } = this.state;
    const styleBtn = {
      padding: '12px 14px',
      fontFamily: 'inherit',
      background: '#EE2A7B',
      color: '#fff',
      border: 'none',
      display: 'inline-block',
      fontSize: '1.6rem',
      cursor: 'pointer',
      margin: '1.2rem 0.6rem'
    }


    return (
      <Layout layoutProps={{ location, footerData: pageContext.footerData, pageNavData: pageContext.pageNavData }}>
        <SEO />
        <div className="page-content-wrapper">
          <div className="page-section m-pnav">
            <div style={{
              maxWidth: '1200px',
              margin: '4rem auto',
            }}>
              <div
                style={{
                  background: '#333',
                  borderRadius: '1rem'
                }}
              >
                <Lottie
                  isPaused={!isAnimPlay}
                  options={{ animationData: anim }}
                  speed={animSpeed}
                />
              </div>
              <div style={{textAlign: 'center', fontFamily: 'inherit', fontSize: '2rem', margin: '3rem auto'}}>{`Speed: ${animSpeed * 100}%`}</div>
              <div style={{textAlign: 'center'}}>
                <button
                  style={{
                    ...styleBtn,
                    cursor: animSpeed <= 0.5 ? 'not-allowed' : 'pointer',
                    opacity: animSpeed <= 0.5 ? '0.3' : '1'
                  }}
                  onClick={() => {
                    if (animSpeed > 0.5) {
                      this.setState({
                        animSpeed: animSpeed - 0.5
                      })
                    }
                  }}
                  disabled={animSpeed <= 0.5}
                >Speed --</button>
                <button
                  onClick={() => this.setState({ isAnimPlay: !isAnimPlay})}
                  style={styleBtn}
                >
                  {isAnimPlay ? 'Pause' : 'Play'}
                </button>
                <button
                  style={styleBtn}
                  onClick={() => this.setState({animSpeed: animSpeed + 0.5})}
                >Speed ++</button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}


export default LottiePage
